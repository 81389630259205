import {
  Typography,
  makeStyles,
  Grid,
  useMediaQuery,
  Button,
  Slide,
  CssBaseline,
  ThemeProvider,
} from "@material-ui/core"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import ContentPreview from "../components/ContentPreview"
import CoreValues from "../components/CoreValues"

import Layout from "../components/Layout"
import Partners from "../components/Partners"
import customTheme from "../gatsby-theme-material-ui-top-layout/customTheme"

export const query = graphql`
  {
    allFile(
      filter: {
        relativePath: { in: ["course-1.jpg", "course-2.jpg", "course-3.jpg"] }
      }
    ) {
      nodes {
        courseImage: childrenImageSharp {
          gatsbyImageData(width: 400, height: 250, placeholder: BLURRED)
        }
      }
    }
    posts: allContentfulBlogPost(
      sort: { fields: date, order: DESC }
      limit: 3
    ) {
      nodes {
        title
        image {
          gatsbyImageData(width: 400, height: 250, placeholder: BLURRED)
        }
        summary {
          summary
        }
        tags
        slug
      }
    }
  }
`

const useStyles = makeStyles({
  sectionSeperator: {
    marginBottom: "8rem",
  },
  sectionSeperatorShadowed: {
    borderRadius: "100%",
    margin: "6rem auto 8rem",
    boxShadow: "0 14px 0px -6px #660000",
    width: "40%",
    height: "1.4rem",
  },
  horizontalCardDetails: {
    paddingRight: " 4rem",
    [customTheme.breakpoints.down("sm")]: {
      paddingRight: " 0rem",
    },
  },
  horizontalCardImage: {
    maxWidth: "40rem",
    boxShadow: "0 8px 6px -6px #000",
    [customTheme.breakpoints.down("sm")]: {
      marginBottom: "1rem",
    },
  },
  titleLG: {
    fontSize: "3.2rem",
    fontWeight: "bold",
    [customTheme.breakpoints.down("sm")]: {
      fontSize: "2.4rem",
    },
    [customTheme.breakpoints.down("xs")]: {
      fontSize: "1.6rem",
    },
  },
  description: {
    ...customTheme.typography.descMD,
    fontSize: "1.8rem",
    [customTheme.breakpoints.down("md")]: {
      fontSize: "1.6rem",
    },
    [customTheme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
    [customTheme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  button: {
    fontSize: "2rem",
    margin: "4rem auto 0",
    [customTheme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
    [customTheme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
    "&:hover": {
      color: "#fff",
    },
  },
  // Training programs
  centeredTitle: {
    fontSize: "3.2rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "4rem",
    [customTheme.breakpoints.down("sm")]: {
      fontSize: "2.4rem",
    },
    [customTheme.breakpoints.down("xs")]: {
      fontSize: "1.6rem",
    },
  },
})

export default function Home({ data }) {
  const classes = useStyles()
  const matches960 = useMediaQuery("(max-width: 960px)")

  const consultationServices = {
    title: "الخدمات الاستشارية في مجال الاتصالات وأمن وتقنية المعلومات",
    description:
      "بخبرات تراكمية تفوق الثلاثين عاما لعناصر شركة التميز وشركائها في مجال الاتصالات وتقنية المعلومات، ستوفر الشركة الخدمات الاستشارية لكل الجهات الراغبة بما سينعكس إيجابا على توفير الجهد، والوقت والميزانيات لصالح الجهات المستفيدة. وفي سبيل ذلك ستضمن لكم شركة التميز قدرات فريقها الاستشاري المهنية والإدارية والتواصل مع كافة الأطراف في الخدمات التي تخص المشاريع وقدرتها على توفير النصح بخصوص افضل الممارسات الدولية لانجازها.",
  }

  return (
    <Layout
      isHero
      pageMeta={{
        title: "الرئيسية",
        description:
          "شركة التميز للخدمات الامنية وللاستشارات في مجال أمن المعلومات، دورات بشهادات معتمدة iso pcidss وحماية أمنية احترافية بأحدث الوسائل",
        keywords: [
          "أمن المعلومات",
          "حماية أمنية",
          "استشارات أمنية",
          "خدمات أمنية",
          "شهادات أمنية معتمدة",
          "آيزو",
          "iso",
          "pcidss",
          "ليبيا",
          "libya",
        ],
      }}
    >
      <ThemeProvider theme={customTheme}>
        <CssBaseline />
        <Grid container direction="column">
          {/* Services */}
          <Grid
            item
            container
            direction={matches960 ? "column" : "row"}
            justify="center"
            alignItems="center"
            className={classes.sectionSeperator}
            spacing={4}
          >
            <Slide direction="left" in={true} mountOnEnter unmountOnExit>
              <Grid item md={4} className={classes.horizontalCardImage}>
                <StaticImage
                  src="../images/consultation.jpg"
                  alt="people meeting in office"
                />
              </Grid>
            </Slide>
            <Grid item md={8}>
              <Grid
                container
                direction="column"
                className={classes.horizontalCardDetails}
              >
                <Typography
                  className={classes.titleLG}
                  paragraph
                  align={matches960 ? "center" : "left"}
                >
                  {consultationServices.title}
                </Typography>
                <Typography
                  className={classes.description}
                  align={matches960 ? "center" : "left"}
                >
                  {consultationServices.description}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container justify="center">
              <Button
                color="primary"
                variant="contained"
                component={Link}
                to="/services"
                className={classes.button}
              >
                كل الخدمات
              </Button>
            </Grid>
          </Grid>

          {/* Core values */}
          <Grid item container direction="column">
            <CoreValues />
            <div className={classes.sectionSeperatorShadowed} />
          </Grid>

          {/* Training programs */}
          <Grid item container direction="column">
            <Grid container direction="column">
              <Grid item align="center">
                <Typography className={classes.centeredTitle}>
                  الدورات والبرامج التدريبية لحمايتك وحماية مؤسستك
                </Typography>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <ContentPreview
                    data={data}
                    content="courses"
                    direction="column"
                    isButton
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container justify="center">
              <Button
                color="primary"
                variant="contained"
                component={Link}
                to="/training_courses"
                className={classes.button}
              >
                كل الدورات والبرامج التدريبية
              </Button>
            </Grid>
            <div className={classes.sectionSeperatorShadowed} />
          </Grid>

          {/* ISO */}
          <Grid item container direction="column">
            <Grid item container direction="column">
              <Grid item>
                <Typography className={classes.centeredTitle} paragraph>
                  معيار نظام إدارة أمن المعلومات - آيزو 27001
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={classes.description}
                  align={matches960 ? "center" : "right"}
                >
                  يركز المعيار آيزو 27001 على حماية معلومات المؤسسة على مستوى
                  الخصوصية، السلامة والقدرة على الوصول. ينجز هذا العمل من خلال
                  إيجاد المشاكل المحتملة التي يمكن أن تحدث للمعلومات (مايسمى
                  تقييم الخطر)، ومن ثم تحديد الاحتياجات المطلوبة لمنع مثل هذه
                  المشاكل من الحدوث (مايسمى تقليل المخاطر أو معالجة المخاطر).
                  وبناء عليه فإن فلسفة المعيار آيزو 27001 تقوم على أساس إدارة
                  المخاطر: إيجاد مكامن الأخطار، ومن ثم معالجتها بطريقة منهجية.
                  <br />
                  ستعمل شركة التميز للاستشارات وأمن المعلومات على تقديم المساعدة
                  للمؤسسات الليبية فيما يخص تطبيق نظام إدارة أمن المعلومات من
                  خلال مهندسيها لتغطية جميع المتطلبات الضرورية وتنفيذ ضوابط
                  الحماية حسب ما سيتم سرده ضمن بيان التطابق بحيث يتم وضع أدوات
                  الحماية في مكانها المناسب وضمن أفضل ميزانية وتوقيت.
                </Typography>
              </Grid>
              <Grid item container justify="center">
                <Button
                  color="primary"
                  variant="contained"
                  component={Link}
                  to="/iso_services"
                  className={classes.button}
                >
                  لمعرفة المزيد
                </Button>
              </Grid>
            </Grid>
            <Grid item className={classes.sectionSeperatorShadowed} />
          </Grid>

          {/* PCIDSS */}
          <Grid item container direction="column">
            <Grid item container direction="column">
              <Grid item>
                <Typography className={classes.centeredTitle} paragraph>
                  معيار أمن بيانات صناعة بطاقات الدفع الائتمانية PCI DSS
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={classes.description}
                  align={matches960 ? "center" : "right"}
                >
                  معيار أمن بيانات صناعة بطاقة الدفع الإئتمانية (PCI DSS) هو
                  معيار صمم لدعم وتعزيز حماية بيانات حاملي البطاقات الإئتمانية
                  وضمان الالتزام الدولي بمقياس موحد لتقييم ذلك.
                  <br />
                  يعمل الالتزام بالمعيار على تزويد المؤسسات المالية، مقدمي
                  الخدمات وجميع الأطراف المشتركة في التعامل مع بيانات حامل
                  البطاقة بداية من نقاط البيع، التاجر، العملاء الماليين،
                  المصدرين، مقدمي الخدمة والمصرف المشتري بالمبادئ الاساسية
                  للمتطلبات التشغيلية والتقنية المصممة لحماية بيانات الحسابات
                  الخاصة بحاملي البطاقات.
                  <br />
                  كما ينطبق المعيار على جميع الهيئات الأخرى التي تعمل على تخزين،
                  معالجة أو نقل بيانات حاملي البطاقات (CHD) و/أو بينات المصادقة
                  الحساسة (SAD).
                </Typography>
              </Grid>
              <Grid item container justify="center">
                <Button
                  color="primary"
                  variant="contained"
                  component={Link}
                  to="/pcidss_services"
                  className={classes.button}
                >
                  لمعرفة المزيد
                </Button>
              </Grid>
            </Grid>
            <Grid item className={classes.sectionSeperatorShadowed} />
          </Grid>

          {/* Partners */}
          <Grid item container direction="column">
            <Grid item container direction="column">
              <Grid item>
                <Typography className={classes.centeredTitle}>
                  شركاء التميز
                </Typography>
              </Grid>
              <Grid item>
                <Partners />
              </Grid>
            </Grid>
            <Grid item className={classes.sectionSeperatorShadowed} />
          </Grid>

          {/* Call to action */}
          <Grid
            item
            container
            direction="column"
            justify="center"
            align="center"
            spacing={2}
          >
            <Grid item>
              <Typography className={classes.centeredTitle} paragraph>
                كيف يمكننا تأمينك؟
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.description} paragraph>
                نعمل على تأمين بياناتك بمجموعة من الاجراءات والعمليات الاستباقية
                لفهم المخاطر والتهديدات وتوفير التدريب والمعرفة اللازمة وتجهيز
                فرق قادرة على مراقبة واصطياد التهديدات بخبرة واسعة تفوق 25 عاما
                وتجارب متعددة في التعامل مع التهديدات الأمنية
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                component={Link}
                to="/contact"
                className={classes.button}
              >
                تواصل معنا
              </Button>
            </Grid>
            <Grid item className={classes.sectionSeperator} />
          </Grid>

          {/* Blog --- To reactivate when there is some posts to show */}
          {/* {data.posts && (
            <Grid item container direction="column">
              <Grid item container direction="column" alignItems="center">
                <Grid item>
                  <Typography className={classes.centeredTitle}>
                    آخر أخبار المدونة
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <ContentPreview
                      data={data}
                      content="blogPosts"
                      direction="column"
                      isButton
                    />
                  </Grid>
                </Grid>
                <Grid item container justify="center">
                  <Button
                    color="primary"
                    variant="contained"
                    component={Link}
                    to="/blog"
                    className={classes.button}
                  >
                    المدونة
                  </Button>
                </Grid>
              </Grid>
              <Grid item className={classes.sectionSeperator} />
            </Grid>
          )} */}
        </Grid>
      </ThemeProvider>
    </Layout>
  )
}
